import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { alertActions } from "../../../actions/alert.actions";
import { StandardService } from "../../../services";
import { commaValidation } from "../../../utility";
import { BookingDetailsService } from "../../../services";
import { produce } from "immer";

const useStandard = (location, t) => {
  // console.log("Href Link", window.location.href);
  const [adult, setAdult] = useState(
    localStorage.getItem("adult") ? Number(localStorage.getItem("adult")) : 0
  );
  const [singleOccupancyCharge, setSigleOccupancyCharge] = useState(0);
  const dispatch = useDispatch();
  const [children, setChildren] = useState(
    localStorage.getItem("children")
      ? Number(localStorage.getItem("children"))
      : 0
  );
  const [totalRoomCharge, setTotalRoomCharge] = useState(0);
  const [totalGstCharge, setTotalGstCharge] = useState(0);
  const [totalPayableAmount, setTotalPayableAmount] = useState(0);
  const [rooms, setRooms] = useState(
    localStorage.getItem("rooms") ? Number(localStorage.getItem("rooms")) : 1
  );
  const [roomData, setRoomData] = useState("");
  const [roomAdded, setRoomAdded] = useState("NO");
  let [roomAmount, setRoomAmount] = useState("");
  const urlParams = new URLSearchParams(window.location.search);
  const room_id = urlParams.get("id");
  let calculatedTotal = 0;
  let calculatedGstCharge = 0;
  // let totalPayableAmount = 0;

  const [showShipping, setShowShipping] = useState(false);
  const [pathimg, setPathimg] = useState();
  const [singleOccCharge, setSingleOccCharge] = useState(0);
  const [extraOccCharge, setExtraOccCharge] = useState(0);
  const [formattedArrivalDate, setFormattedArrivalDate] = useState("");
  const [formattedDepartureDate, setFormattedDepartureDate] = useState("");
  const [datetimevalue, setdatetimevalue] = useState(
    localStorage.getItem("datetime1")
      ? localStorage
          .getItem("datetime1")
          .replace(
            "00:00:00 GMT+0530" ||
              "01:00:00 GMT+0530" ||
              "02:00:00 GMT+0530" ||
              "03:00:00 GMT+0530" ||
              "04:00:00 GMT+0530" ||
              "05:00:00 GMT+0530" ||
              "06:00:00 GMT+0530" ||
              "07:00:00 GMT+0530" ||
              "08:00:00 GMT+0530" ||
              "09:00:00 GMT+0530" ||
              "10:00:00 GMT+0530" ||
              "11:00:00 GMT+0530" ||
              "12:00:00 GMT+0530" ||
              "13:00:00 GMT+0530" ||
              "14:00:00 GMT+0530" ||
              "15:00:00 GMT+0530" ||
              "16:00:00 GMT+0530" ||
              "17:00:00 GMT+0530" ||
              "18:00:00 GMT+0530" ||
              "19:00:00 GMT+0530" ||
              "20:00:00 GMT+0530" ||
              "21:00:00 GMT+0530" ||
              "22:00:00 GMT+0530" ||
              "23:00:00 GMT+0530" ||
              "24:00:00 GMT+0530",
            "14:00:00 GMT+0530"
          )
      : ""
  );
  const [datetimevalue1, setdatetimevalue1] = useState(
    localStorage.getItem("datetime2")
      ? localStorage
          .getItem("datetime2")
          .replace(
            "00:00:00 GMT+0530" ||
              "01:00:00 GMT+0530" ||
              "02:00:00 GMT+0530" ||
              "03:00:00 GMT+0530" ||
              "04:00:00 GMT+0530" ||
              "05:00:00 GMT+0530" ||
              "06:00:00 GMT+0530" ||
              "07:00:00 GMT+0530" ||
              "08:00:00 GMT+0530" ||
              "09:00:00 GMT+0530" ||
              "10:00:00 GMT+0530" ||
              "11:00:00 GMT+0530" ||
              "12:00:00 GMT+0530" ||
              "13:00:00 GMT+0530" ||
              "14:00:00 GMT+0530" ||
              "15:00:00 GMT+0530" ||
              "16:00:00 GMT+0530" ||
              "17:00:00 GMT+0530" ||
              "18:00:00 GMT+0530" ||
              "19:00:00 GMT+0530" ||
              "20:00:00 GMT+0530" ||
              "21:00:00 GMT+0530" ||
              "22:00:00 GMT+0530" ||
              "23:00:00 GMT+0530" ||
              "24:00:00 GMT+0530",
            "12:00:00 GMT+0530"
          )
      : ""
  );
  const [checkindate, setCheckindate] = useState();
  const [checkoutdate, setCheckoutdate] = useState();
  const [checkintime, setCheckintime] = useState("");
  const [checkouttime, setCheckouttime] = useState("");
  const phone = useSelector((state) => state?.userData?.user?.phone);
  const name = useSelector((state) => state?.userData?.user?.name);
  const navigate = useNavigate();
  const [service, setService] = useState([]);
  const [extra, setExtra] = useState(0);
  const [diffDate, setDiffDate] = useState(0);
  const [amountData, setAmountData] = useState([]);
  const [amountData1, setAmountData1] = useState([]);
  const [addAmount, setAddAmount] = useState("");
  const [addAmount1, setAddAmount1] = useState("");
  const [loader, setLoader] = useState("notset");
  const [timediff, setTimediff] = useState();
  var timedifferences;
  var updatedList = [];
  var updatedList1 = [];
  var dataPath;
  var updatedService = [];
  var updatedService1 = [];
  var servicearray = [];
  const [servId, setServiceId] = useState([]);
  const [upService, setUpService] = useState([]);
  const [upService1, setUpService1] = useState([]);
  const [adultcount, setAdultcount] = useState([]);
  const [totservice, setTotalService] = useState([]);
  var adults = 0;
  const [datafinalvalue, setdatafinalvalue] = useState(0);
  const [datafinaltax, setdatafinaltax] = useState(0);
  const [displaycount, setdisplaycount] = useState(true);
  const [actamount, setactamount] = useState(0);
  const [singleOccupied, setsingleOccupied] = useState([]);
  const [extraOccupied, setExtraOccupied] = useState([]);
  const [singleRoomInfo, setSingleRoomInfo] = useState([]);
  var checkInDate = checkindate;

  var update = [];
  var extradata = 0;
  var total_pay_amount = 0;
  var countlength = 0;
  var taxdata = 0;
  var singletax = 0;
  var skiptax = 0;
  var totTax = 0;
  var actualamount = 0;
  var skipdayamount = 0;

  // var extradata;
  /**
   * date calculation
   */
  useEffect(() => {
    if (datetimevalue && datetimevalue1) {
      const date1 = new Date(datetimevalue);
      const date2 = new Date(datetimevalue1);
      const diffInMs = Math.abs(date2 - date1);
      let moddata = diffInMs / (1000 * 60 * 60 * 24);
      // eslint-disable-next-line
      timedifferences = Math.trunc(Number(moddata));
      setTimediff(timedifferences + 1);
      if (Number.isInteger(moddata)) {
        setDiffDate(moddata);
      } else {
        setDiffDate(Math.trunc(Number(moddata + 1)));
      }
    }
    // eslint-disable-next-line
  }, [checkindate, checkoutdate, checkintime, checkouttime]);

  useEffect(() => {
    /**
     * the sum of amount that is coming from api loop
     */

    roomData?.[0]?.room_amount &&
      roomData?.[0]?.room_amount.map((data) => {
        if (data?.date >= checkindate && data?.date < checkoutdate) {
          actualamount = Number(actualamount) + Number(data?.price);
          countlength = Number(countlength + 1);
          adultcount.map((data1) => {
            if (data1 == 1) {
              total_pay_amount =
                Number(total_pay_amount) +
                Number(data?.price - roomData?.[0]?.single_occupancy_price);
              if (
                Number(data?.price - roomData?.[0]?.single_occupancy_price) >=
                7500
              ) {
                singletax =
                  Number(singletax) +
                  Number(data?.price - roomData?.[0]?.single_occupancy_price) *
                    Number(18 / 100);
              } else {
                singletax =
                  Number(singletax) +
                  Number(data?.price - roomData?.[0]?.single_occupancy_price) *
                    Number(12 / 100);
              }
            } else if (data1 == 2) {
              total_pay_amount = Number(total_pay_amount) + Number(data?.price);
              if (Number(data?.price) >= 7500) {
                singletax =
                  Number(singletax) +
                  Number(Number(data?.price) * Number(18 / 100));
              } else {
                singletax =
                  Number(singletax) +
                  Number(Number(data?.price) * Number(12 / 100));
              }
            } else {
              total_pay_amount =
                Number(total_pay_amount) +
                Number(data?.price) +
                Number(roomData?.[0]?.extra_occupancy);
              if (
                Number(data?.price) + Number(roomData?.[0]?.extra_occupancy) >=
                7500
              ) {
                singletax =
                  Number(singletax) +
                  Number(
                    Number(
                      Number(data?.price) +
                        Number(roomData?.[0]?.extra_occupancy)
                    ) * Number(18 / 100)
                  );
              } else {
                singletax =
                  Number(singletax) +
                  Number(
                    Number(
                      Number(data?.price) +
                        Number(roomData?.[0]?.extra_occupancy)
                    ) * Number(12 / 100)
                  );
              }
            }
          });
        }
        //console.log(singletax);
      });

    //skip day amount calculation
    setdisplaycount(true);
    // set true if singleoccpied  and extraoccpied appears
    var singOccupiedArr = [],
      extrOccupiedArr = [];
    adultcount.map((data1) => {
      adults = Number(adults) + Number(data1);
      if (Number(data1) === 1) {
        singOccupiedArr.push(true);
        setsingleOccupied(singOccupiedArr);
      } else {
        singOccupiedArr.push(false);
        setsingleOccupied(singOccupiedArr);
      }
      if (Number(data1) === 3) {
        extrOccupiedArr.push(true);
        setExtraOccupied(extrOccupiedArr);
      } else {
        extrOccupiedArr.push(false);
        setExtraOccupied(extrOccupiedArr);
      }

      // setdisplaycount(true);
      if (adultcount.length > 0 && data1 == "") {
        setdisplaycount(false);
      } else {
        if (data1 == 1) {
          skipdayamount =
            Number(skipdayamount) +
            Number(roomData?.[0]?.price) -
            Number(roomData?.[0]?.single_occupancy_price);
          if (
            Number(
              roomData?.[0]?.price - roomData?.[0]?.single_occupancy_price
            ) >= 7500
          ) {
            skiptax =
              Number(skiptax) +
              (Number(
                roomData?.[0]?.price - roomData?.[0]?.single_occupancy_price
              ) *
                18) /
                100;
          } else {
            skiptax =
              Number(skiptax) +
              (Number(
                roomData?.[0]?.price - roomData?.[0]?.single_occupancy_price
              ) *
                12) /
                100;
          }
        } else if (data1 == 2) {
          skipdayamount = Number(skipdayamount) + Number(roomData?.[0]?.price);
          if (Number(roomData?.[0]?.price) >= 7500) {
            skiptax =
              Number(skiptax) + (Number(roomData?.[0]?.price) * 18) / 100;
          } else {
            skiptax =
              Number(skiptax) + (Number(roomData?.[0]?.price) * 12) / 100;
          }
        } else {
          skipdayamount =
            Number(skipdayamount) +
            Number(roomData?.[0]?.price) +
            Number(roomData?.[0]?.extra_occupancy);

          if (
            Number(roomData?.[0]?.price) +
              Number(roomData?.[0]?.extra_occupancy) >=
            7500
          ) {
            skiptax =
              Number(skiptax) +
              Number(
                Number(
                  Number(roomData?.[0]?.price) +
                    Number(roomData?.[0]?.extra_occupancy)
                ) * Number(18 / 100)
              );
          } else {
            skiptax =
              Number(skiptax) +
              Number(
                Number(
                  Number(roomData?.[0]?.price) +
                    Number(roomData?.[0]?.extra_occupancy)
                ) * Number(12 / 100)
              );
          }
        }
      }
    });

    extradata =
      Number(total_pay_amount) +
      Number(skipdayamount) * Number(diffDate - countlength);

    // let data = 0;
    // const lastIndex = singleRoomInfo.length - 1;
    // singleRoomInfo.slice(0, lastIndex).forEach((room) => {
    //   data += room.price;
    // });
    // extradata = Number(data);
    // for (let i = 1; i < adultcount.length; i++) {
    //   console.log("mmmmmmmm");
    //   if (adultcount[i] === "2") {
    //     extradata = Number(extradata);
    //   }
    //   if (adultcount[i] === "1") {
    //     extradata = Number(extradata) - Number(singleOccCharge);
    //   }
    //   if (adultcount[i] === "3") {
    //     extradata = Number(extradata) - Number(extraOccCharge);
    //   }
    // }
    //console.log(adultcount[0], "nnnnn");

    // adultcount.forEach((count) => {
    //   console.log(count, "nnn");
    // });

    // extradata = singleRoomInfo.forEach((room) => {
    //   if (room.price) {
    //     extradata += room.price;
    //   }
    // });
    // let data = 0;
    // singleRoomInfo.slice(0, -1).forEach((room) => {
    //   data += room.price;
    // });
    setdatafinalvalue(extradata);
    actualamount =
      Number(actualamount) +
      Number(roomData?.[0]?.price) * Number(diffDate - countlength);
    setactamount(actualamount);
    totTax =
      Number(singletax) + Number(skiptax) * Number(diffDate - countlength);
    setdatafinaltax(totTax);
    setAdult(adults);
  }, [adultcount, checkindate, checkoutdate, displaycount, singleRoomInfo]);

  /**
   * get info
   */
  useEffect(() => {
    getRoomInfo();
    roomInfo();

    if (localStorage.getItem("id") !== "null") {
      let updatedLists;
      let elements = [];
      updatedLists = localStorage.getItem("id");
      if (updatedLists) {
        let lines = updatedLists.split(",");

        for (let i = 0; i < lines.length; i++) {
          elements.push(lines[i]);
        }

        if (elements[0] !== "") {
          setServiceId(elements);
        } else {
          setServiceId([]);
        }
      }
    }

    if (localStorage.getItem("service") !== "null") {
      let updatedLists;
      let elements = [];
      updatedLists = localStorage.getItem("service");
      if (updatedLists) {
        let lines = updatedLists.split(",");

        for (let i = 0; i < lines.length; i++) {
          elements.push(lines[i]);
        }

        if (elements[0] !== "") {
          setUpService(elements);
        } else {
          setUpService([]);
        }
      }
    }

    if (localStorage.getItem("service1") !== "null") {
      let updatedLists;
      let elements = [];
      updatedLists = localStorage.getItem("service1");
      if (updatedLists) {
        let lines = updatedLists.split(",");

        for (let i = 0; i < lines.length; i++) {
          elements.push(lines[i]);
        }

        if (elements[0] !== "") {
          setUpService1(elements);
        } else {
          setUpService1([]);
        }
      }
    }

    if (localStorage.getItem("amountdata") !== "null") {
      let updatedLists;
      let elements = [];
      updatedLists = localStorage.getItem("amountdata");
      if (updatedLists) {
        let lines = updatedLists.split(",");

        for (var i = 0; i < lines.length; i++) {
          elements.push(lines[i]);
        }

        if (elements[0] !== "") {
          setAmountData(elements);
        } else {
          setAmountData([]);
        }
      }
    }
    if (localStorage.getItem("amountdata1") !== "null") {
      let updatedLists;
      let elements = [];
      updatedLists = localStorage.getItem("amountdata1");
      if (updatedLists) {
        let lines = updatedLists.split(",");

        for (let i = 0; i < lines.length; i++) {
          elements.push(lines[i]);
        }

        if (elements[0] !== "") {
          setAmountData1(elements);
        } else {
          setAmountData1([]);
        }
      }
    }
  }, []);

  useEffect(() => {
    convert(localStorage.getItem("datetime1"));
    convertCheckout(localStorage.getItem("datetime2"));
    localStorage.setItem("datetime1", datetimevalue);
    localStorage.setItem("datetime2", datetimevalue1);
    // eslint-disable-next-line
  }, [checkindate, checkoutdate, checkintime, checkouttime]);

  useEffect(() => {
    var amountDataCount = 0;
    var amountDataCount1 = 0;
    var tot_count = Number(adult) + Number(children);
    amountData.map(
      (data) =>
        (amountDataCount =
          Number(amountDataCount) +
          Number(data) * Number(diffDate === 0 ? 1 : diffDate))
    );
    amountData1.map(
      (data) => (amountDataCount1 = Number(amountDataCount1) + Number(data))
    );
    amountDataCount =
      Number(amountDataCount) * Number(tot_count <= 0 ? 1 : tot_count);
    setAddAmount(amountDataCount);
    setAddAmount1(amountDataCount1);

    setExtra(Number(addAmount) + Number(addAmount1));
    localStorage.setItem("adult", Number(adult));
    localStorage.setItem("children", Number(children));
    localStorage.setItem("rooms", Number(rooms));

    if (adult === 1 || rooms * roomData?.[0]?.no_of_occupancy - adult === 1) {
      extradata = extradata - roomData?.[0]?.single_occupancy_price;
    }

    // eslint-disable-next-line
  }, [adult, rooms, children, amountData, amountData1, addAmount, addAmount1]);

  useEffect(() => {
    service &&
      service.map((data) => {
        servId &&
          servId.map((data1) => {
            if (data?._id === data1) {
              if (data?.people_choice === true) {
                let servicetotal =
                  data?.price *
                  (children + adult > 0 ? children + adult : 1) *
                  (diffDate === 0 ? 1 : diffDate);

                update.push({ id: data1, price: servicetotal });
              } else {
                let servicetotal = commaValidation(
                  Number(data?.price * (diffDate === 0 ? 1 : diffDate))
                );

                update.push({ id: data1, price: servicetotal });
              }
            }
          });
      });
    setTotalService(update);
  }, [adult, rooms, children, service, diffDate, servId]);

  /**
   * get room info
   */
  const getRoomInfo = () => {
    setLoader("loading");
    StandardService.getRoomInfo(location?.state?.id)
      .then((data) => {
        setSingleOccCharge(
          data?.data?.data?.[0]?.no_of_occupancy *
            data?.data?.data?.[0]?.single_occupancy_price
        );
        setSingleOccCharge(
          data?.data?.data?.[0]?.no_of_occupancy *
            data?.data?.data?.[0]?.extra_occupancy
        );
        if (data?.data?.status?.code === 0) {
          setRoomData(data?.data?.data);
          setLoader("isfull");
        }
      })
      .catch((error) => {
        console.log("Errorreg", error);
      });
  };

  /**
   * get room info
   */
  const roomInfo = () => {
    setLoader("loading");
    StandardService.online()
      .then((res) => {
        setService(res?.data?.data?.service);
        setLoader("isfull");
      })
      .catch((error) => {
        console.log("Errorreg", error);
      });
  };

  useEffect(() => {
    if (checkindate !== undefined && checkoutdate !== undefined) {
      getSingleRoomInfo();
    }
  }, [checkindate, checkoutdate]);

  //get single room info
  const getSingleRoomInfo = () => {
    setLoader("loading");
    StandardService.getSingleRoomInfo(
      location?.state?.id,
      checkindate,
      checkoutdate
    )
      .then((data) => {
        if (data?.data?.status?.code === 0) {
          setSingleRoomInfo(data?.data?.data?.rooms[0]?.room_amount);
          setLoader("isfull");
        }
      })
      .catch((error) => {
        console.log("Errorreg", error);
      });
  };

  /**
   * continue to back
   * api call only after entering all correct details
   * @param {*} e
   */
  const continuToBook = (e) => {
    e.preventDefault();

    if (!rooms) {
      dispatch(alertActions.error(t("Enter_Valid_Rooms_Count")));
    } else if (!datetimevalue || !datetimevalue1) {
      dispatch(alertActions.error(t("Enter_Both_Check-In_and_Check-Out_Time")));
    } else if (new Date(datetimevalue) >= new Date(datetimevalue1)) {
      dispatch(alertActions.error(t("Checkin_and_Checkout_Date_is_Invalid")));
    } else if (!displaycount) {
      dispatch(
        alertActions.error(t("Please select adult count for all rooms"))
      );
    } else {
      var costData = Number(
        roomData?.[0]?.price * rooms * (diffDate > 0 ? diffDate : 1) +
          (adult + children > 4
            ? roomData?.[0]?.extra_occupancy * (adult + children - 4)
            : 0)
      );

      if (checkindate && checkintime && checkoutdate && checkouttime) {
        setLoader("loading");
        StandardService.onlinebook1(
          checkindate,
          "2:00 PM",
          checkoutdate,
          "12:00 PM",
          adult,
          children,
          location?.state?.id,
          adultcount.length,
          Number(roomData?.[0]?.price ? roomData?.[0]?.price : 0),
          extra,
          totalPayableAmount,
          Math.round(diffDate).toFixed(0),
          name,
          phone,
          timediff,
          diffDate,
          servId,
          totservice,
          roomData?.[0]?.extra_occupancy,
          roomData?.[0]?.single_occupancy_price,
          datafinaltax,
          roomData?.[0]?.no_of_occupancy,
          singleOccupied,
          extraOccupied,
          totalGstCharge
        )
          .then((res) => {
            if (res?.data?.status?.code === 0) {
              dataPath = res?.data?.data?.[0]?._id;
              navigate(`/bookingDetails?id=${dataPath}`, {
                state: {
                  data: res?.data?.data,
                  date: diffDate,
                  timediff: timediff,
                  diff: diffDate,
                  adultcount: adultcount,
                  datafinalvalue: datafinalvalue,
                  actualamt: totalPayableAmount,
                  taxamt: totalGstCharge,
                  roomchargeamt: totalRoomCharge,
                },
              });
              setLoader("isfull");
            } else {
              dispatch(alertActions.error(res?.data?.status?.message));
              window.location.reload();
              setLoader("isfull");
            }
          })
          .catch((err) => console.log(err));
      }
    }
  };

  /**
   * model
   */
  const closeShipping = () => {
    document.body.classList.remove("modal-open");
    setShowShipping(false);
  };
  /**
   * model display
   */
  const openShipping = () => {
    document.body.classList.add("modal-open");
    setShowShipping(true);
  };
  /**
   * chenge image on image click
   * @param {*} data
   */
  const selectPath = (data) => {
    setPathimg(data);
  };

  /**
   * get time and date
   * @param {*} str
   */
  function convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2),
      hours = 12,
      minutes = ("0" + date.getMinutes()).slice(-2);

    setCheckindate([date.getFullYear(), mnth, day].join("-"));
    setCheckintime([hours, minutes].join(":"));
  }

  /**
   * convert check out
   * @param {*} str
   */
  function convertCheckout(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2),
      hours = 23,
      minutes = ("0" + date.getMinutes()).slice(-2);

    setCheckoutdate([date.getFullYear(), mnth, day].join("-"));
    setCheckouttime([hours, minutes].join(":"));
  }
  /**
   * set localstorage value
   * @param {*} e
   */
  const onChangeValue = (e) => {
    localStorage.setItem("datetime1", e);
    setdatetimevalue(
      localStorage.getItem("datetime1")
        ? localStorage
            .getItem("datetime1")
            .replace(
              "00:00:00 GMT+0530" ||
                "01:00:00 GMT+0530" ||
                "02:00:00 GMT+0530" ||
                "03:00:00 GMT+0530" ||
                "04:00:00 GMT+0530" ||
                "05:00:00 GMT+0530" ||
                "06:00:00 GMT+0530" ||
                "07:00:00 GMT+0530" ||
                "08:00:00 GMT+0530" ||
                "09:00:00 GMT+0530" ||
                "10:00:00 GMT+0530" ||
                "11:00:00 GMT+0530" ||
                "12:00:00 GMT+0530" ||
                "13:00:00 GMT+0530" ||
                "14:00:00 GMT+0530" ||
                "15:00:00 GMT+0530" ||
                "16:00:00 GMT+0530" ||
                "17:00:00 GMT+0530" ||
                "18:00:00 GMT+0530" ||
                "19:00:00 GMT+0530" ||
                "20:00:00 GMT+0530" ||
                "21:00:00 GMT+0530" ||
                "22:00:00 GMT+0530" ||
                "23:00:00 GMT+0530" ||
                "24:00:00 GMT+0530",
              "12:00:00 GMT+0530"
            )
        : ""
    );
    convert(e);
  };
  /**
   * set localstorage value for checkout date
   * @param {*} e
   */
  const onChangeValue1 = (e) => {
    localStorage.setItem("datetime2", e);
    setdatetimevalue1(
      localStorage.getItem("datetime2")
        ? localStorage
            .getItem("datetime2")
            .replace(
              "00:00:00 GMT+0530" ||
                "01:00:00 GMT+0530" ||
                "02:00:00 GMT+0530" ||
                "03:00:00 GMT+0530" ||
                "04:00:00 GMT+0530" ||
                "05:00:00 GMT+0530" ||
                "06:00:00 GMT+0530" ||
                "07:00:00 GMT+0530" ||
                "08:00:00 GMT+0530" ||
                "09:00:00 GMT+0530" ||
                "10:00:00 GMT+0530" ||
                "11:00:00 GMT+0530" ||
                "12:00:00 GMT+0530" ||
                "13:00:00 GMT+0530" ||
                "14:00:00 GMT+0530" ||
                "15:00:00 GMT+0530" ||
                "16:00:00 GMT+0530" ||
                "17:00:00 GMT+0530" ||
                "18:00:00 GMT+0530" ||
                "19:00:00 GMT+0530" ||
                "20:00:00 GMT+0530" ||
                "21:00:00 GMT+0530" ||
                "22:00:00 GMT+0530" ||
                "23:00:00 GMT+0530" ||
                "24:00:00 GMT+0530",
              "11:00:00 GMT+0530"
            )
        : ""
    );
    convertCheckout(e);
  };

  /**
   * check price
   * @param {*} val
   * @param {*} members
   * @param {*} e
   *
   * checkprice functionality for extra service
   */

  const checkPrice = (service, val, members, e, id) => {
    servicearray = [...servId];
    updatedList = [...amountData];
    updatedList1 = [...amountData1];

    updatedService = [...upService];
    updatedService1 = [...upService1];
    if (e.target.checked) {
      servicearray = [...servId, id];
      if (members) {
        updatedList = [...amountData, val];
        updatedService = [...upService, service];
      } else {
        updatedList1 = [...amountData1, val];
        updatedService1 = [...upService1, service];
      }
    } else {
      servicearray.splice(servId.indexOf(id), 1);
      if (members) {
        updatedList.splice(amountData.indexOf(val), 1);
        updatedService.splice(upService.indexOf(service), 1);
      } else {
        updatedList1.splice(amountData1.indexOf(val), 1);
        updatedService1.splice(upService1.indexOf(service), 1);
      }
    }
    setServiceId(servicearray);
    setAmountData(updatedList);
    setAmountData1(updatedList1);

    setUpService(updatedService);
    setUpService1(updatedService1);
    localStorage.setItem("amountdata", [updatedList]);
    localStorage.setItem("id", [servicearray]);
    localStorage.setItem("amountdata1", [updatedList1]);
    // console.log(servicearray)
    localStorage.setItem("service", [updatedService]);
    localStorage.setItem("service1", [updatedService1]);
  };

  /**
   * handle checkbox
   * @param {*} e
   */

  const isInCategory = (title) => {
    return upService.includes(title) || upService1.includes(title);
  };
  /**
   * initially taking one array and pushing all dropdown price in array
   */
  const handleAdd = () => {
    if (
      !checkindate ||
      checkindate == "NaN-aN-aN" ||
      !checkoutdate ||
      checkoutdate == "NaN-aN-aN"
    ) {
      dispatch(
        alertActions.error("Please select valid checkin and checkout date")
      );
    } else {
      setRoomAdded("YES");
      //console.log("Add Room Entered");
      const newdata = [...adultcount, []];
      setAdultcount(newdata);
    }
  };

  const convertDate = (datetimevalue) => {
    const dateObject = new Date(datetimevalue);
    const formattedDate = dateObject.toISOString().split("T")[0];
    return formattedDate;
  };

  useEffect(() => {
    setFormattedArrivalDate(convertDate(datetimevalue));
    setFormattedDepartureDate(convertDate(datetimevalue1));
  }, []);
  /**
   * change the particular index value
   * @param {*} value
   * @param {*} i
   */
  const handleChange = (value, i) => {
    const inputdata = [...adultcount];
    inputdata[i] = value.target.value;
    setAdultcount(inputdata);
  };

  useEffect(() => {
    const convertedResponse = adultcount.map((adult_count, index) => ({
      number: index + 1,
      adult_count: parseInt(adult_count),
    }));

    localStorage.setItem("adult_count", JSON.stringify(convertedResponse));

    BookingDetailsService.amount_calculation(
      formattedArrivalDate,
      formattedDepartureDate,
      room_id,
      convertedResponse
    )
      .then((res) => {
        if (res?.data?.status?.code === 0) {
          res?.data?.data?.slice(0, -1).forEach((item) => {
            item?.detail?.forEach((detailItem) => {
              const roomCharge = detailItem?.roomCharge;
              if (typeof roomCharge === "number") {
                calculatedTotal += roomCharge;
              }
            });
          });
          setTotalRoomCharge(calculatedTotal);
          res?.data?.data?.slice(0, -1).forEach((item) => {
            item?.detail?.forEach((detailItem) => {
              const gst = detailItem?.gst;
              if (typeof gst === "number") {
                calculatedGstCharge += gst;
              }
            });
          });
          setTotalGstCharge(calculatedGstCharge);
          setTotalPayableAmount(calculatedTotal + calculatedGstCharge);
        } else {
          console.log("ERROR MESSAGE", res.data.status.message);
        }
      })
      .catch((error) => {
        console.log("Errorreg", error);
      });
  }, [adultcount]);
  /**
   * remove one textbox at a time
   * @param {*} i
   */
  const handleDelete = (i) => {
    const deleteval = [...adultcount];
    deleteval.splice(i, 1);
    setAdultcount(deleteval);
  };

  return {
    checkindate,
    checkoutdate,
    adult,
    children,
    rooms,
    showShipping,
    roomData,
    pathimg,
    datetimevalue,
    datetimevalue1,
    service,
    extra,
    diffDate,
    loader,
    extradata,
    taxdata,
    adultcount,
    datafinalvalue,
    datafinaltax,
    singleRoomInfo,
    roomAmount,
    totalRoomCharge,
    totalGstCharge,
    totalPayableAmount,
    handleDelete,
    handleChange,
    handleAdd,
    isInCategory,
    continuToBook,
    onChangeValue1,
    closeShipping,
    openShipping,
    selectPath,
    convert,
    onChangeValue,
    checkPrice,
    roomAdded,
  };
};
export default useStandard;
